import axios from 'axios';
import apiCall from '@/shared/api/index';

export const register = ({email, password, firstName, lastName, phoneNumber }, token) => {

  return axios.post(`${process.env.VUE_APP_BASE_API}/${token ? 'register/' + token : 'register'}`, { email, password, firstName, lastName, phoneNumber })
};

export const verify = (email, verificationCode) => {
  return axios.post(`${process.env.VUE_APP_BASE_API}/verify-email`, { email, verificationCode });
};
export const getEmailFromInvitation = (token) => {
  return axios.get(`${process.env.VUE_APP_BASE_API}/invite/email/${token}`);
};

export const resendVerification = (email) => {
  return axios.post(`${process.env.VUE_APP_BASE_API}/resend-verification`, { email });
};

export const login = async (email, password) => {
  // const res = await axios.post(`${process.env.VUE_APP_BASE_API}/login`, { email, password });
  const res = await apiCall({ url: '/login', method: 'POST', data:  { email, password } });
  const token = res.data.token;
  const expiresIn = res.data.expiresIn;
  const expirationDate = new Date().getTime() + expiresIn * 1000;

  localStorage.setItem('token', token);
  localStorage.setItem('tokenExpiration', expirationDate);
  localStorage.setItem('user', JSON.stringify(res.data.user)); // Store user data

  return { user: res.data.user, token, tokenExpiration: expirationDate };
};
export const removeStorage = async () => {
  localStorage.clear();
}

export const logout = async (router) => {
  const token = localStorage.getItem('token');
  await removeStorage();

  apiCall({ url: '/logout', method: 'POST', ...(token ? { headers: { Authorization: `Bearer ${token}` }  } : {}) });
  const currentRoute = router.currentRoute;

  if (currentRoute.name !== 'login' && currentRoute.name !== 'register') {
    router.push({ name: 'login' });
  }
}

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  const expirationDate = localStorage.getItem('tokenExpiration');
  const now = new Date().getTime();

  if (!token || now >= expirationDate) {
    logout();
    return false;
  }
  return true;
};

export const sendVerificationSMS = (phoneNumber, email) => {
  return axios.post(`${process.env.VUE_APP_BASE_API}/send-verification-sms`, { phoneNumber, email });
};

export const getConfig = () => {
  return axios.get(`${process.env.VUE_APP_BASE_API}/configuration`);
};

export const updateConfig = (verificationProcess) => {
  return axios.post(`${process.env.VUE_APP_BASE_API}/configuration`, { verificationProcess });
};

export async function requestPasswordReset(email) {
  const response = await axios.post(`${process.env.VUE_APP_BASE_API}/forgot-password`, { email });
  return response.data;
}

export async function resetPassword(token, newPassword) {
  const response = await axios.post(`${process.env.VUE_APP_BASE_API}/reset-password/${token}`, { password: newPassword });
  return response.data;
}

export const getUserProfile = async () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return Promise.reject('No token found');
  }
  return await apiCall({ url: '/profile', method: 'GET' }).then((res) => res.data);
};
